import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  Para,
  Chat,
  Language,
  ExternalLink,
} from "./styles";

const Footer = ({ t }: any) => {

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact us")}</Language>
              <Para>
                {t(`Are you interesting in working for or investing in Hinesight?`)}
              </Para>
              <a href="mailto:danny@hinesight.io">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Links")}</Title>
              <ExternalLink href="https://en.wikipedia.org/wiki/Prediction_market" target="_blank" rel="noopener noreferrer">
                {t("Prediction Markets")}
              </ExternalLink>
              {/* <ExternalLink href="http://mason.gmu.edu/~rhanson/futarchy.pdf" target="_blank" rel="noopener noreferrer">
                {t("Bet on Beleifs")}
              </ExternalLink> */}
              <ExternalLink href="https://www.americangaming.org/research/state-gaming-map/" target="_blank" rel="noopener noreferrer">
                {t("Sports Betting in the U.S.")}
              </ExternalLink>
            </Col>
            <Col lg={6} md={6} sm={0} xs={0} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Para>© 2021 Hinesight, LLC. <br />All Rights Reserved</Para>
              </div>
            </Col>
          </Row>
        </Container>

      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
